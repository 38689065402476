var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('currentLocation',{attrs:{"sibItem":_vm.sibItem}}),_c('div',{staticClass:"outerOne"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/img/dsBJ3.png"),"alt":""}}),_c('div',[_c('div',{staticClass:"oneTitle"},[_vm._v(" "+_vm._s(_vm.$t("processPlatformDepartment.PPD_oneTitle"))+" ")]),_c('div',{staticClass:"oneCont"},[_vm._v(" "+_vm._s(_vm.$t("processPlatformDepartment.PPD_oneCont"))+" ")])])])]),_vm._m(0),_c('div',{staticClass:"outerThree"},[_c('div',{staticClass:"threeCont"},[_c('div',{staticClass:"fourContOne"},[_c('div',{staticClass:"contOne"},[_c('div',{staticClass:"contYuan"},_vm._l((_vm.contYuan),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"yuanItemOne"},[_vm._v(_vm._s(item.name1))]),_c('div',{staticClass:"yuanItemOne yuanItemTwo"},[_vm._v(_vm._s(item.name2))])])}),0),_c('div',{staticClass:"contXian"},[_vm._v(" "+_vm._s(_vm.$t("processPlatformDepartment.PPD_threeTitle"))+" ")]),_vm._m(1),_c('div',{staticClass:"threeModular"},_vm._l((_vm.$t(
              'processPlatformDepartment.PPD_threeModular'
            )),function(item,index){return _c('div',{key:index,staticClass:"modularItem"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{staticClass:"threeTip",domProps:{"innerHTML":_vm._s(_vm.$t('processPlatformDepartment.PPD_threeTip'))}})])])]),_c('div',{staticClass:"outerFour"},[_c('div',{staticClass:"fourTip fourTipTwo"},[_vm._v(" "+_vm._s(_vm.$t("processPlatformDepartment.PPD_fourTip"))+" ")]),_c('div',{staticClass:"fourCont"},_vm._l((_vm.$t(
        'processPlatformDepartment.PPD_fourModular'
      )),function(item,index){return _c('div',{key:index,staticClass:"contItem fourContTwo"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{domProps:{"innerHTML":_vm._s(item.name)}})])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outerTwo"},[_c('div',{staticClass:"fourTipOne"},[_c('img',{staticClass:"ppdImg5",attrs:{"src":require("@/assets/img/ppdImg5.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contJiantou"},[_c('img',{attrs:{"src":require("@/assets/img/ppdImgjiantou.png"),"alt":""}})])
}]

export { render, staticRenderFns }