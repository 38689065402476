<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outerOne">
      <div>
        <img src="@/assets/img/dsBJ3.png" alt="" />
        <div>
          <div class="oneTitle"> {{ $t("processPlatformDepartment.PPD_oneTitle") }} </div>
          <div class="oneCont"> {{ $t("processPlatformDepartment.PPD_oneCont") }} </div>
        </div>
      </div>
    </div>
    <div class="outerTwo">
      <div class="fourTipOne">
        <!-- <img src="@/assets/img/ppdImg1.png" alt="" class="ppdImg1" />
        <div class="twoTip">
          <div v-html="$t('processPlatformDepartment.PPD_twoTitle')"></div>
          <img src="@/assets/img/ppdImg2.png" alt="" class="ppdImg2" />
        </div> -->
        <img src="@/assets/img/ppdImg5.png" alt="" class="ppdImg5" />
      </div>
    </div>
    <div class="outerThree">
      <div class="threeCont">
        <div class="fourContOne">
          <div class="contOne">
            <div class="contYuan">
              <div v-for="(item, index) in contYuan" :key="index">
                <div class="yuanItemOne">{{ item.name1 }}</div>
                <div class="yuanItemOne yuanItemTwo">{{ item.name2 }}</div>
              </div>
            </div>
            <div class="contXian"> {{ $t("processPlatformDepartment.PPD_threeTitle") }} </div>
            <div class="contJiantou">
              <img src="@/assets/img/ppdImgjiantou.png" alt="" />
            </div>
            <div class="threeModular">
              <div class="modularItem" v-for="(item, index) in $t(
                'processPlatformDepartment.PPD_threeModular'
              )" :key="index">
                <img :src="item.img" alt="" />
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div v-html="$t('processPlatformDepartment.PPD_threeTip')" class="threeTip"></div>
        </div>
      </div>
    </div>
    <div class="outerFour">
      <div class="fourTip fourTipTwo"> {{ $t("processPlatformDepartment.PPD_fourTip") }} </div>
      <div class="fourCont">
        <div v-for="(item, index) in $t(
          'processPlatformDepartment.PPD_fourModular'
        )" :key="index" class="contItem fourContTwo">
          <img :src="item.img" alt="" />
          <div v-html="item.name"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：工艺研发平台部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-10-08 17:32
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "departmentSettings",
        },
        {
          title_zh: "工艺研发平台部",
          title_en: "Process R&D Platform Department",
          path: "",
        },
      ],
      contYuan: [
        {
          name1: "新型存储",
          name2: "New memory",
        },
        {
          name1: "新材料",
          name2: "New material",
        },
        {
          name1: "新工艺",
          name2: "New process",
        },
        {
          name1: "新结构",
          name2: "New structure",
        },
        {
          name1: "国产化",
          name2: "Localization",
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.handleScrollboxNow();
  },
  methods: {
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-9-29
     */
    handleScrollboxNow() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".outerOne").offset().top - 950) {
        this.$(".outerOne>div").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipOne").offset().top - 950) {
        this.$(".fourTipOne").addClass("showDiv");
      }
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$(".fourContOne").offset().top - 580) {
        this.$(".fourContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipTwo").offset().top - 600) {
        this.$(".fourTipTwo").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContTwo").offset().top - 580) {
        this.$(".fourContTwo").addClass("showDiv");
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
  padding-bottom: 130px;
}

.outerOne {
  padding: 80px 310px;

  >div {
    display: flex;
    align-items: flex-start;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 30px;
    }

    div {
      .oneTitle {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }

      .oneCont {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}

.fourTipOne,
.fourContOne,
.fourTipTwo,
.fourContTwo {
  transition: all 0.6s ease-in 0s;
  transform: translateY(30px);
  opacity: 0;
}

.outerTwo {
  background: #ffffff;
  padding: 40px 260px;

  >div {
    display: flex;
    justify-content: center;

    .ppdImg5 {
      width: 100%;
      // height: 440px;
      object-fit: contain !important;
    }

    .ppdImg1 {
      width: 420px;
      height: 320px;
      margin: 0 60px 40px 0;
    }

    .twoTip {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // padding: 40px 0 0;

      div {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #323232;
      }

      .ppdImg2 {
        width: 270px;
        height: 160px;
        object-fit: contain !important;
      }


    }
  }
}

.outerThree {
  padding: 70px 260px 80px;

  .threeCont {
    background: #1a2a60;
    padding: 58px 50px;
  }

  .contOne {
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(26, 42, 96, 0.5);
    border-radius: 12px;
    margin-bottom: 47px;
    padding: 40px 66px;

    .contYuan {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 29px;

      >div {
        background: #b92424;
        box-shadow: 0px 9px 12px 0px rgba(185, 36, 36, 0.3);
        border-radius: 50%;
        width: 144px;
        height: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .yuanItemOne {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }

        .yuanItemTwo {
          font-size: 16px;
        }
      }

      >div:nth-child(2n) {
        background: #4f63a7 !important;
        box-shadow: 0px 9px 12px 0px rgba(79, 99, 167, 0.3) !important;
      }
    }

    .contXian {
      background: #1a2a60;
      padding: 16px 0;
      text-align: center;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .contJiantou {
      display: flex;
      justify-content: center;
      margin: 17px 0 29px;

      img {
        height: 75px;
        object-fit: contain !important;
      }
    }

    .threeModular {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .modularItem {
        border: 1px solid #4f63a7;
        width: 12%;
        // height: 198px;
        padding: 13px;

        >img {
          width: 100%;
          // height: 153px;
        }

        >div {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2a2b2e;
          text-align: center;
          margin-top: 6px;
        }
      }
    }
  }

  .threeTip {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 54px;
    padding: 0 180px;
    text-align: center;
  }
}

.outerFour {
  .fourTip {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
    padding: 0px 310px 60px;
  }

  .fourCont {
    background: #ffffff;
    padding: 60px 310px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contItem {
      display: flex;
      align-items: center;

      img {
        width: 320px;
        height: 320px;
      }

      div {
        background: #1a2a60;
        width: 230px;
        height: 160px;
        padding: 60px 39px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        margin-left: -60px;
      }
    }
  }
}
</style>